/* WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust the color of the scrollbar thumb */
  /* border-radius: 5px; Adjust the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
}

/* Firefox */
body {
  scrollbar-width: thin; /* "thin" or "auto", adjust as needed */
}

body::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit-based browsers when scrollbar-width is set */
}
